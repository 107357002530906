import styled from 'styled-components';

import { Card } from '../components/Card';
import { H1 } from '../components/Typography';

export const Title = styled(H1)`
  color: #f5f6ff;
  font-weight: 300;
  max-width: 900px;
`;

export const SampleDocumentPage = styled.div<{ wide?: boolean }>`
  background: white;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  width: 404px;
  height: 531px;
  right: -35px;
  top: auto;
  bottom: 0;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  padding-top: 26px;
  overflow: hidden;
`;

export const TimelineCard = styled(Card)<{ isWide?: boolean }>`
  max-width: 770px;
  width: 100%;
  height: auto;
  padding-top: 50px;
  padding-bottom: 50px;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  background-color: #04093d;
  margin-top: ${({ isWide }) => (isWide ? -95 : 30)}px;
  min-width: ${({ isWide }) => (isWide ? 670 : 0)}px;
`;

export const Text = styled.p`
  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  text-align: center;
  text-decoration: none;
  color: black;

  b {
    font-weight: 500;
  }
`;
