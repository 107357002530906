import { AxiosError } from 'axios';
import { converge, either, find, isEmpty, isNil, nthArg, pathOr, pipe, propEq } from 'ramda';

export const isEmptyOrNil = either(isNil, isEmpty);
export const getBodyMessage = (error: AxiosError, message = 'Something went wrong, please try again later'): string =>
  pathOr(message, ['message'])(error);

function random(min: number, max: number): number {
  return Math.floor(Math.random() * (max - min) + min);
}

export function generateGuestToken(): string {
  const charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

  return Array.from(Array(random(15, 25)), () => charset.charAt(random(0, charset.length))).join('');
}

export function tryAutomaticDownload(url: string): void {
  try {
    if (typeof window !== 'undefined') {
      const element = document.createElement('a');
      element.setAttribute('href', url);

      element.style.display = 'none';
      document.body.appendChild(element);

      element.click();

      document.body.removeChild(element);
    }
  } catch {
    console.error('Could not automatically download file');
  }
}

export const findById = converge(find, [pipe(nthArg(0), propEq('id')), nthArg(1)]);
