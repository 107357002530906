import styled from 'styled-components';

import { Colors } from '../utils/style-utils';

export const Container = styled.div`
  max-width: 820px;
  padding: 60px 50px;
  margin-left: auto;
  margin-right: auto;
`;

export const Warning = styled.div`
  background: #f5f6ff;
  border-radius: 15px;
  padding: 27px 35px;
`;

export const HelpText = styled.span<{ positive?: boolean; negative?: boolean }>`
  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: ${({ positive, negative }) => {
    if (positive) {
      return '#219653';
    }

    if (negative) {
      return '#EB5757';
    }

    return '#333';
  }};
  margin: 14px 0;
`;

export const EditButton = styled.button.attrs({
  type: 'button',
})`
  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  color: ${Colors.Blue500};
`;

export const Loading = styled.div`
  position: absolute;
  background-color: white;
  z-index: 90;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`;

export const Cell = styled.div`
  background-color: transparent;
  border-radius: 0;

  &.fill {
    background-color: #fafafa;
    border-radius: 15px;
    padding-bottom: 1rem;
  }
`;
