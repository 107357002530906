import styled from 'styled-components';

export const Label = styled.label`
  font-family: 'Norse', sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 24px;
  color: #2f3144;
  margin: 14px 0;
`;
